"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformLeadTypesToSuggestionList = exports.getVisibleTabs = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _schema = require("../config/schema/schema.tabs");
var _constants = require("../tools/constants");
const transformLeadTypesToSuggestionList = (allLeadTypes, selectedLeadSource, t) => {
  if (!allLeadTypes.length) return [];
  const leadTypes = allLeadTypes.map(d => ({
    id: d?.id,
    value: (0, _commonsUtils.nullOrString)(d?.id),
    label: d?.type
  })).sort((a, b) => a?.label?.localeCompare(b?.label) || -1);
  let suggestionList = [];
  let otherList = [];
  const suggestionTypesByLeadSource = _constants.LEAD_TYPES_SUGGESTION_GROUPING?.[selectedLeadSource];
  if (!suggestionTypesByLeadSource) return leadTypes;
  leadTypes.forEach(type => {
    if (suggestionTypesByLeadSource.includes(type.id)) {
      suggestionList.push(type);
    } else {
      otherList.push(type);
    }
  });
  if (!suggestionList.length) return otherList;
  return [{
    label: t('leads.createView.createLead.createForm.SUGGESTIONS'),
    options: suggestionList
  }, {
    label: t('leads.createView.createLead.createForm.OTHER_TYPES'),
    options: otherList
  }];
};
exports.transformLeadTypesToSuggestionList = transformLeadTypesToSuggestionList;
const getVisibleTabs = (lead, rights, disabled) => {
  let newTabs = [..._schema.CONST_TABS];
  if (lead?.tracking) {
    const trackingTabIdx = newTabs.findIndex(t => t.id === 'tracking');
    newTabs.splice(trackingTabIdx, 1, {
      title: 'leads.updateView.trackingForm.TAB_LABEL',
      id: 'tracking',
      body: [{
        legend: 'leads.updateView.trackingForm.TRACKING_INFOS',
        fields: [{
          input: 'tracking'
        }]
      }]
    });
  }
  if (!_constants.LEAD_SERVICE_CATEGORIES.includes(lead?.leadCategoryId)) {
    const serviceTabIdx = newTabs.findIndex(t => t.id === 'service');
    newTabs.splice(serviceTabIdx, 1);
  }
  if (!rights.ManageDocumentLead) {
    const documentsTabIdx = newTabs.findIndex(t => t.id === 'documents');
    newTabs.splice(documentsTabIdx, 1);
  }
  if (!rights.ManageAntiSpam) {
    const antiSpamTabIdx = newTabs.findIndex(t => t.id === 'antiSpam');
    newTabs.splice(antiSpamTabIdx, 1);
  }
  if (!rights.ViewContactFinancing) {
    const financeInfoTabIdx = newTabs.findIndex(t => t.id === 'financeInfo');
    newTabs.splice(financeInfoTabIdx, 1);
  }

  // Compute disable state for each field
  newTabs = newTabs.map(tab => {
    if (tab.body) {
      const updatedBody = tab.body.map(bodyItem => {
        if (bodyItem?.fields) {
          return {
            ...bodyItem,
            fields: bodyItem.fields.map(field => ({
              ...field,
              disabled
            }))
          };
        }
        return bodyItem;
      });
      return {
        ...tab,
        body: updatedBody
      };
    } else return tab;
  });
  return newTabs;
};
exports.getVisibleTabs = getVisibleTabs;