"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DeskingQuestionsModel = _interopRequireDefault(require("./DeskingQuestionsModel"));
var _DilawriCBBModelGenerator = _interopRequireDefault(require("./DilawriCBBModelGenerator"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = exports.default = {
  DeskingQuestionsModel: _DeskingQuestionsModel.default,
  DilawriCBBModelGenerator: _DilawriCBBModelGenerator.default
};