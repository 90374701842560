"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _uiLibrary = require("@sm360/ui-library");
var _commonsUtils = require("@sm360/commons-utils");
var _selectors = require("../../selectors");
var _actions = require("../../actions");
/**
 */
function updateCustomer(_ref) {
  let {
    customer,
    onComplete,
    isCoApplicantSelected = false,
    isCoApplicantEditing = false
  } = _ref;
  return function* () {
    const {
      organizationId,
      organizationUnitId,
      location,
      lang
    } = yield (0, _effects.select)(_selectors.getConfig);
    const leadId = yield (0, _effects.select)(_selectors.getLeadId);
    const queryParams = {
      orgId: organizationId,
      orgUnitId: organizationUnitId,
      location,
      lang
    };
    const displayToast = (message, success) => {
      success ? _uiLibrary.Toast.toast.success(message, _commonsUtils.SHORT_TOAST_OPTIONS) : _uiLibrary.Toast.toast.error(message, _commonsUtils.SHORT_TOAST_OPTIONS);
      if (typeof onComplete === 'function') {
        onComplete();
      }
    };
    try {
      const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.updateCustomer, leadId, queryParams, customer);
      yield (0, _effects.put)(_actions.Creators.setCustomer({
        ...response,
        isCoApplicantSelected,
        isCoApplicantEditing
      }));
      customer.messages && displayToast(customer.messages.success, true);
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'updateCustomer'));
      customer.messages && displayToast(customer.messages.error, false);
    }
  }();
}
var _default = exports.default = updateCustomer;