"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUserImapById = exports.revokeUserOAuthAccess = exports.getUserById = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getUserById = async id => {
  return _appsCore.axios.get(`${CRM_ENDPOINT}/users/${id}`);
};
exports.getUserById = getUserById;
const updateUserImapById = async (id, user) => {
  return _appsCore.axios.put(`${CRM_ENDPOINT}/users/${id}/emailSync`, user, {
    ignoreAppsCoreCatch: true
  });
};
exports.updateUserImapById = updateUserImapById;
const revokeUserOAuthAccess = async id => {
  return _appsCore.axios.post(`${CRM_ENDPOINT}/users/${id}/emailSync/revoke`);
};
exports.revokeUserOAuthAccess = revokeUserOAuthAccess;