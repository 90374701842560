"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isLinkToParentSatisfied = exports.isAnswered = exports.getQuestionnaireProgressStatus = exports.getListCurrentAnswers = exports.getAnswerById = exports.getAllQuestionKeys = exports.findQuestionByIdInAllSections = exports.default = exports.countTotalQuestions = exports.countAnsweredQuestions = void 0;
var _commonsUtils = require("@sm360/commons-utils");
var _SectionStatus = _interopRequireDefault(require("../constants/SectionStatus"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const isAnswered = answer => !(0, _commonsUtils.isEmpty)(answer) || Number.isFinite(answer) || typeof answer === 'boolean';
exports.isAnswered = isAnswered;
const isLinkToParentSatisfied = (question, parentAnswer) => {
  if (!question || !question.linkToParent || !isAnswered(parentAnswer)) {
    return false;
  }
  const {
    linkToParent: {
      onlyFor,
      anyExcept
    }
  } = question;
  const equalsParentValue = value => value === parentAnswer || Array.isArray(parentAnswer) && parentAnswer.includes(value);
  return onlyFor != null && equalsParentValue(onlyFor) || anyExcept != null && !equalsParentValue(anyExcept);
};
exports.isLinkToParentSatisfied = isLinkToParentSatisfied;
const countQuestions = (getBaseValue, shouldCountSubQuestion, model) => {
  const incrementForQuestion = (question, questionKey) => {
    if (!question) {
      return 0;
    }
    let increment = getBaseValue(questionKey);
    if (question.questions) {
      increment += Object.keys(question.questions).reduce((subQuestionsCount, subQuestionKey) => {
        const subQuestion = question.questions[subQuestionKey];
        return shouldCountSubQuestion(subQuestion, questionKey, subQuestionKey) ? subQuestionsCount + 1 : subQuestionsCount;
      }, 0);
    }
    return increment;
  };
  return !(0, _commonsUtils.isEmpty)(model.sections) ? Object.values(model.sections).reduce((totalCount, section) => totalCount + Object.keys(section.questions).reduce((questionsCount, questionKey) => {
    return questionsCount + incrementForQuestion(section.questions[questionKey], questionKey);
  }, 0), 0) : 0;
};
const getAllQuestionKeys = (dataSource, model) => {
  const shouldCountSubQuestion = (question, parentQuestionKey) => dataSource && isLinkToParentSatisfied(question, dataSource[parentQuestionKey]);
  const getKeysForQuestion = (question, questionKey) => {
    let questionKeys = [];
    if (question && question.questions) {
      questionKeys = Object.keys(question.questions).reduce((subQuestionsKeys, subQuestionKey) => {
        const subQuestion = question.questions[subQuestionKey];
        if (shouldCountSubQuestion(subQuestion, questionKey)) {
          subQuestionsKeys.push(subQuestionKey);
        }
        if (subQuestion && subQuestion.questions && !(0, _commonsUtils.isEmpty)(subQuestion.questions)) {
          Object.keys(subQuestion.questions).forEach(subSubQuestionKey => {
            const subSubQuestion = subQuestion.questions[subSubQuestionKey];
            if (shouldCountSubQuestion(subSubQuestion, subQuestionKey)) {
              subQuestionsKeys.push(subSubQuestionKey);
            }
          });
        }
        return subQuestionsKeys;
      }, []);
    }
    return questionKeys;
  };
  if (!(0, _commonsUtils.isEmpty)(model.sections)) {
    return (0, _commonsUtils.flattenDeep)(Object.values(model.sections).map(section => Object.keys(section.questions).reduce((sectionQuestionsKeys, questionKey) => {
      sectionQuestionsKeys.push(questionKey);
      return sectionQuestionsKeys.concat(getKeysForQuestion(section.questions[questionKey], questionKey));
    }, [])));
  }
  return [];
};
exports.getAllQuestionKeys = getAllQuestionKeys;
const countTotalQuestions = function (dataSource, model) {
  let questionsNotCounted = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  return countQuestions(() => 1, (question, parentQuestionKey, questionKey) => {
    if (questionsNotCounted.indexOf(questionKey) !== -1) {
      return false;
    }
    return dataSource && isLinkToParentSatisfied(question, dataSource[parentQuestionKey]);
  }, model);
};
exports.countTotalQuestions = countTotalQuestions;
const countAnsweredQuestions = (dataSource, model, questionsNotCounted) => {
  return countQuestions(questionKey => dataSource && isAnswered(dataSource[questionKey]) ? 1 : 0, (question, parentQuestionKey, questionKey) => {
    if (Array.isArray(questionsNotCounted) && questionsNotCounted.indexOf(questionKey) !== -1) {
      return false;
    }
    return dataSource && isLinkToParentSatisfied(question, dataSource[parentQuestionKey]) && isAnswered(dataSource[questionKey]);
  }, model);
};
exports.countAnsweredQuestions = countAnsweredQuestions;
const getQuestionById = (questions, id) => {
  for (const questionId in questions) {
    const question = questions[questionId];
    if (questionId === id) {
      return question;
    }
    if (question?.questions) {
      const subQuestions = question.questions;
      const res = getQuestionById(subQuestions, id);
      if (res) return res;
    }
  }
};
const findQuestionByIdInAllSections = (questionId, model) => {
  if (!model || !model?.sections) return undefined;
  for (const sectionId in model.sections) {
    const section = model.sections[sectionId];
    const question = getQuestionById(section.questions, questionId);
    if (question) return question;
  }
};
exports.findQuestionByIdInAllSections = findQuestionByIdInAllSections;
const getAnswerById = (id, question) => {
  if (question?.choices && question.choices.length > 0) {
    for (const choice of question.choices) {
      if (choice.value === id) return choice;
    }
  }
  return undefined;
};
exports.getAnswerById = getAnswerById;
const getListCurrentAnswers = (answers, model, translate) => {
  return Object.keys(answers).map(questionId => {
    const question = findQuestionByIdInAllSections(questionId.toString(), model);
    const answer = getAnswerById(answers[questionId], question);
    if (question && answer) {
      return {
        question: translate ? translate(question.labelKey) : question.labelKey,
        answer: translate ? translate(answer.labelKey) : answer.labelKey,
        indent: Boolean(question?.linkToParent && !(0, _commonsUtils.isEmpty)(question.linkToParent))
      };
    }
    return undefined;
  }).filter(item => !!item);
};

// Returns the current progress status of the questionnaire based on the answers
exports.getListCurrentAnswers = getListCurrentAnswers;
const getQuestionnaireProgressStatus = (evaluationAnswers, model) => {
  const {
    NOT_STARTED,
    COMPLETED,
    STARTED
  } = _SectionStatus.default;
  if (!evaluationAnswers) return NOT_STARTED;
  const questionsCount = countTotalQuestions(evaluationAnswers, model);
  const answersCount = countAnsweredQuestions(evaluationAnswers, model);
  let sectionsStatus = NOT_STARTED;
  if (questionsCount === answersCount) sectionsStatus = COMPLETED;else if (answersCount > 0) sectionsStatus = STARTED;
  return sectionsStatus;
};
exports.getQuestionnaireProgressStatus = getQuestionnaireProgressStatus;
var _default = exports.default = {
  isAnswered,
  isLinkToParentSatisfied,
  getAllQuestionKeys,
  countTotalQuestions,
  countAnsweredQuestions,
  findQuestionByIdInAllSections,
  getAnswerById,
  getListCurrentAnswers,
  getQuestionnaireProgressStatus
};