"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.URL_REGEX = exports.SHORT_TOAST_OPTIONS = exports.PHONE_REGEX = exports.LEAD_CAR_TYPES = exports.IP_REGEX = exports.HTML_STYLE_TAG_REGEX = exports.HOSTNAME_REGEX = exports.EMAIL_REGEX = exports.EMAIL_OR_DOMAIN_REGEX = exports.EMAILS_REGEX = exports.CA_US_POSTAL_CODE_REGEX = void 0;
const CA_US_POSTAL_CODE_REGEX = exports.CA_US_POSTAL_CODE_REGEX = new RegExp('^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$');
const EMAIL_REGEX = exports.EMAIL_REGEX = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const EMAILS_REGEX = exports.EMAILS_REGEX = new RegExp(/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/);
const EMAIL_OR_DOMAIN_REGEX = exports.EMAIL_OR_DOMAIN_REGEX = new RegExp(/^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$|^(((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,}))$/);
const PHONE_REGEX = exports.PHONE_REGEX = new RegExp(/(?!0000000000)(?:([+]?[0-9]{1,2})[-\s.])?[(]?([0-9]{3})[)]?[-\s.]?([0-9]{3})[-\s.]?([0-9]{4,6})/);
const URL_REGEX = exports.URL_REGEX = new RegExp(/(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))/);
const HOSTNAME_REGEX = exports.HOSTNAME_REGEX = new RegExp(/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/);
const IP_REGEX = exports.IP_REGEX = new RegExp(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/);
const HTML_STYLE_TAG_REGEX = exports.HTML_STYLE_TAG_REGEX = new RegExp(/<style.*>/);
const SHORT_TOAST_OPTIONS = exports.SHORT_TOAST_OPTIONS = {
  autoClose: 2500,
  hideProgressBar: true
};
const LEAD_CAR_TYPES = exports.LEAD_CAR_TYPES = {
  CATALOG: 1,
  INVENTORY: 2,
  OTHER: 3,
  UNLISTED: 4
};