"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLeadVehicleKeywords = exports.getLeadInformationKeywords = exports.getLeadEmployeesKeywords = exports.getLeadContactKeywords = void 0;
var _commonsUtils = require("@sm360/commons-utils");
const getLeadInformationKeywords = (lead, lang) => {
  const langSuffix = lang === 'fr' ? 'Fr' : 'En';
  const leadId = lead?.leadId || '';
  const leadMedia = lead?.leadMedia || '';
  const localizedLeadStatus = lead?.leadStatus || lead?.[`leadStatus${langSuffix}`] || '';
  const localizedLeadType = lead?.leadType || lead?.[`leadType${langSuffix}`] || '';
  const localizedLeadSource = lead?.leadSource || lead?.[`leadSource${langSuffix}`] || '';
  const localizedTrackingType = lead?.trackingType || lead?.[`trackingType${langSuffix}`] || '';
  return `${localizedLeadStatus} ${leadId} ${localizedLeadType} ${localizedLeadSource} ${leadMedia} ${localizedTrackingType}`.trim();
};
exports.getLeadInformationKeywords = getLeadInformationKeywords;
const getLeadContactKeywords = lead => {
  const contactFirstName = lead?.contactFirstName || '';
  const contactLastName = lead?.contactLastName || '';
  const contactBusinessName = lead?.contactBusinessName || '';
  const contactPhones = lead?.contact?.phones ? Object.entries(lead.contact.phones).filter(p => !!p[1] && p[1] !== 'null').map(p => (0, _commonsUtils.cleanPhoneNumber)(p[1])).join(' ') : '';
  const contactEmails = lead?.emails ? lead.emails.join(' ') : '';
  const contacTags = lead?.contact?.contactTags?.length > 0 ? lead.contact.contactTags.map(t => t.name).join(' ') : '';
  return `${contactFirstName} ${contactLastName} ${contactBusinessName} ${contactPhones} ${contactEmails} ${contacTags}`.trim();
};
exports.getLeadContactKeywords = getLeadContactKeywords;
const getLeadVehicleKeywords = lead => {
  const vehicleSummaryES = lead?.vehicleSummaryES?.description || '';
  const vehicleTradeIn = lead?.vehicleTradeIn?.description || '';
  const serviceVehicle = lead?.serviceVehicle?.description || '';
  return `${vehicleSummaryES} ${vehicleTradeIn} ${serviceVehicle}`.trim();
};
exports.getLeadVehicleKeywords = getLeadVehicleKeywords;
const getLeadEmployeesKeywords = lead => {
  const ownerName = lead?.ownerName || '';
  const salesmanName = lead?.salesmanName || '';
  const salesManagerName = lead?.salesManagerName || '';
  const businessManagerName = lead?.businessManagerName || '';
  return `${ownerName} ${salesmanName} ${salesManagerName} ${businessManagerName}`.trim();
};
exports.getLeadEmployeesKeywords = getLeadEmployeesKeywords;