"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  step1: 'fa-car',
  step2: 'people-individual',
  step3: 'people-individual',
  step4: 'map-marker',
  step5: 'Mon-Garage',
  step9: 'brochure',
  default: 'people-individual'
};