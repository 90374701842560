"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSubscriptionContexts = exports.getInvalidSubscriptionsByTarget = void 0;
var _request = require("../../tools/request");
const CRM_API_URL = process.env.REACT_APP_CRM_API_URL;

/**
 * @param {Number} organizationId
 * @returns {Promise}
 */
const getSubscriptionContexts = organizationId => {
  return (0, _request.get)(`${CRM_API_URL}/subscriptionContexts?organizationId=${organizationId}`);
};

/**
 * @param {String} target
 * @param {Number} organizationUnitId
 * @param {Number} organizationId
 * @param {String} subscriptionContextSlug
 * @returns {Promise}
 */
exports.getSubscriptionContexts = getSubscriptionContexts;
const getInvalidSubscriptionsByTarget = (target, organizationUnitId, organizationId, subscriptionContextSlug) => {
  const organizationParam = organizationUnitId && organizationUnitId > 0 ? `&organizationUnitId=${organizationUnitId}` : `&organizationUnitId=-1&organizationId=${organizationId}`;
  return (0, _request.get)(`${CRM_API_URL}/subscriptions/invalidByTarget?target=${target}&slug=${subscriptionContextSlug}${organizationParam}`, {
    ignoreAppsCoreCatch: true
  });
};
exports.getInvalidSubscriptionsByTarget = getInvalidSubscriptionsByTarget;