"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.waitForCallAnswer = exports.updatePhoneActivity = exports.createSendSMS = exports.createSendEmail = exports.createPhoneCall = exports.createPhoneActivity = exports.createNote = exports.cancelCall = void 0;
var _appsCore = require("@sm360/apps-core");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// SMS related
const createSendSMS = async _ref => {
  let {
    contactId,
    leadId,
    organizationId,
    userId,
    phoneNumberFrom,
    phoneNumber,
    smsMessage
  } = _ref;
  const leadIdQuery = leadId ? `&leadId=${leadId}` : '';
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/events/sms?contactId=${contactId}${leadIdQuery}&organizationId=${organizationId}&phoneNumberFrom=${phoneNumberFrom}&phoneNumber=${phoneNumber}&userId=${userId}`, smsMessage);
};

// Email related
exports.createSendSMS = createSendSMS;
const createSendEmail = async _ref2 => {
  let {
    mailContent,
    attachments,
    contactId,
    leadId,
    organizationId,
    organizationUnitId,
    userId,
    to,
    replyTo,
    from,
    cc,
    bcc,
    subject,
    subscriptionContext
  } = _ref2;
  const leadIdQuery = leadId ? `&leadId=${leadId}` : '';
  const orgUnitQuery = organizationUnitId ? `&organizationUnitId=${organizationUnitId}` : '';
  const hasAttachments = attachments?.length;
  const path = `/events/mail${hasAttachments ? 'WithAttachment' : ''}`;
  let body = mailContent;
  if (hasAttachments) {
    body = new FormData();
    body.append('body', mailContent);
    attachments.forEach((file, i) => {
      body.append('attachment', file, file?.name || i);
    });
  }
  return await _appsCore.axios.post(`${CRM_ENDPOINT}${path}?contactId=${contactId}&userId=${userId}&organizationId=${organizationId}&to=${to}&replyTo=${replyTo}&from=${from}&cc=${cc}&bcc=${bcc}&subject=${subject}&subscriptionContext=${subscriptionContext}${leadIdQuery}${orgUnitQuery}`, body, {
    ignoreAppsCoreCatch: true,
    headers: {
      'Content-Type': hasAttachments ? 'multipart/form-data' : 'text/plain'
    }
  });
};

// Note related
exports.createSendEmail = createSendEmail;
const createNote = async _ref3 => {
  let {
    body,
    contactId,
    leadId,
    organizationId,
    userId
  } = _ref3;
  const leadIdQuery = leadId ? `&leadId=${leadId}` : '';
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/activities/note?contactId=${contactId}${leadIdQuery}&organizationId=${organizationId}&userId=${userId}`, body);
};

// Phone event related
exports.createNote = createNote;
const createPhoneActivity = async _ref4 => {
  let {
    contactId,
    leadId,
    userId,
    organizationId,
    direction,
    phone,
    interlocutor,
    subject,
    details,
    duration
  } = _ref4;
  const leadIdQuery = leadId ? `&leadId=${leadId}` : '';
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/events/phone?contactId=${contactId}${leadIdQuery}&userId=${userId}&organizationId=${organizationId}&direction=${direction}&phone=${phone}&interlocutor=${interlocutor}&duration=${duration}&details=${details}&subject=${subject}`);
};
exports.createPhoneActivity = createPhoneActivity;
const updatePhoneActivity = async _ref5 => {
  let {
    id,
    userId,
    organizationUnitId,
    interlocutor,
    subject,
    details
  } = _ref5;
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/events/call/${id}?&userId=${userId}&organizationUnitId=${organizationUnitId}&interlocutor=${interlocutor}&details=${details}&subject=${subject}`);
};

// Call related
exports.updatePhoneActivity = updatePhoneActivity;
const createPhoneCall = async _ref6 => {
  let {
    contactId,
    leadId,
    userId,
    organizationId,
    direction,
    phone,
    interlocutor,
    duration,
    details,
    subject
  } = _ref6;
  const leadIdQuery = leadId ? `&leadId=${leadId}` : '';
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/events/call?contactId=${contactId}${leadIdQuery}&userId=${userId}&organizationId=${organizationId}&direction=${direction}&phone=${phone}&interlocutor=${interlocutor}&duration=${duration}&details=${details}&subject=${subject}`);
};
exports.createPhoneCall = createPhoneCall;
const waitForCallAnswer = async _ref7 => {
  let {
    callId,
    answeredClient
  } = _ref7;
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/events/call/${callId}/waitAnswer?answeredClient=${answeredClient}`);
};
exports.waitForCallAnswer = waitForCallAnswer;
const cancelCall = async _ref8 => {
  let {
    callId
  } = _ref8;
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/events/call/${callId}/cancel`);
};
exports.cancelCall = cancelCall;