"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * @param {Object} state
 * @param {Object} isAddDMSCustomerModalOpen
 * @returns {Object} new state
 */
var _default = (state, _ref) => {
  let {
    isAddDMSCustomerModalOpen
  } = _ref;
  return {
    ...state,
    isAddDMSCustomerModalOpen
  };
};
exports.default = _default;