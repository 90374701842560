"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.timeStampToStringDate = exports.setUTCBirthDate = exports.getUTCBirthDate = exports.dateToTimeStamp = void 0;
const timeStampToStringDate = timeStamp => {
  if (timeStamp) {
    let time = timeStamp;
    if (typeof time !== 'number') {
      time = parseInt(time, 10);
    }
    return new Date(time).toString();
  }
  return null;
};
exports.timeStampToStringDate = timeStampToStringDate;
const dateToTimeStamp = value => {
  if (value) {
    let date = value;
    if (!(value instanceof Date) || Object.prototype.toString.call(date) !== '[object Date]') {
      date = new Date(value);
    }
    return date.getTime();
  }
  return null;
};
exports.dateToTimeStamp = dateToTimeStamp;
const getUTCBirthDate = dateTime => {
  if (dateTime !== null && dateTime !== undefined) {
    let date;
    if ((Math.abs(dateTime)?.toString()?.length ?? Infinity) <= 10) {
      date = new Date(dateTime * 1000);
    } else {
      date = new Date(dateTime);
    }
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    return date.valueOf() / 1000;
  }
  return null;
};
exports.getUTCBirthDate = getUTCBirthDate;
const setUTCBirthDate = dateTime => {
  if (dateTime !== null && dateTime !== undefined) {
    let date;
    if ((Math.abs(dateTime)?.toString()?.length ?? Infinity) <= 10) {
      date = new Date(dateTime * 1000);
    } else {
      date = new Date(dateTime);
    }
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    // Date is 0 based in the backend, so we don't want 01/01/1970 to be 0
    // If date value is 0, add 1 hour (3600 seconds)
    return date?.valueOf() / 1000 || 3600;
  }
  return null;
};
exports.setUTCBirthDate = setUTCBirthDate;