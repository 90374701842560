"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../actions");
var _selectors = require("../../selectors");
function transformCustomField(customField) {
  const newField = {
    ...customField
  };
  if (customField.valueType === 'boolean') {
    newField.value = customField.value === 'true';
  } else if (customField.valueType === 'number') {
    newField.value = Number(customField.value) || 0;
  } else if (customField.valueType === 'paragraph') {
    newField.valueType = 'string';
  }
  return newField;
}
function _default(_ref) {
  let {
    formInstanceId,
    customFields,
    checkedLang
  } = _ref;
  return function* () {
    const quoteId = yield (0, _effects.select)(_selectors.getQuoteId);
    const queryParams = yield (0, _effects.select)(_selectors.getShowroomQueryParams);
    const customFieldsCopy = structuredClone(customFields);
    const body = {
      customFields: customFieldsCopy.map(transformCustomField)
    };
    if (checkedLang) {
      queryParams.lang = checkedLang;
    }
    try {
      yield (0, _effects.put)(_actions.Creators.setTabFormsIsPrinting(true));
      const response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.transactionPdfRequest, quoteId, formInstanceId, queryParams, body);
      const pdfData = new Blob([response], {
        type: 'application/pdf'
      });
      const fileURL = URL.createObjectURL(pdfData);
      window.open(fileURL);
      yield (0, _effects.put)(_actions.Creators.setTabFormsIsPrinting(false));
    } catch (error) {
      yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'openPdfFile'));
      yield (0, _effects.put)(_actions.Creators.setTabFormsIsPrinting(false));
    }
  }();
}