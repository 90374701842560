"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateFormsCustomFields = exports.updateFormCustomFields = exports.updateCustomFieldGroup = exports.updateCustomField = exports.statusColor = void 0;
// formsHelpers.js
const statusColor = statusCode => {
  switch (statusCode) {
    case 0:
    case 4:
    default:
      return 'warning';
    case 1:
    case 5:
      return 'secondary';
    case 2:
      return 'success';
    case 3:
    case 6:
      return 'alert';
  }
};
exports.statusColor = statusColor;
const updateCustomField = (customField, matchingField, response) => {
  const updatedField = {
    ...customField
  };
  if (response.formLanguageOverrideCode && customField.formFieldCode === 'FormLanguageCode') {
    updatedField.value = response.formLanguageOverrideCode;
  } else if (matchingField && matchingField.resetCustomValue) {
    updatedField.value = matchingField.value;
  }
  return updatedField;
};
exports.updateCustomField = updateCustomField;
const updateCustomFieldGroup = (customFieldGroup, matchingGroup, response) => {
  return {
    ...customFieldGroup,
    fields: customFieldGroup.fields.map(customField => {
      const matchingField = matchingGroup.fields.find(field => field.formFieldCode === customField.formFieldCode);
      return updateCustomField(customField, matchingField, response);
    })
  };
};
exports.updateCustomFieldGroup = updateCustomFieldGroup;
const updateFormCustomFields = (form, response) => {
  return {
    ...form,
    customFields: form.customFields.map(customFieldGroup => {
      const matchingGroup = response.groups.find(group => group.formFieldsGroupCode === customFieldGroup.formFieldsGroupCode);
      if (matchingGroup) {
        return updateCustomFieldGroup(customFieldGroup, matchingGroup, response);
      }
      return customFieldGroup;
    })
  };
};
exports.updateFormCustomFields = updateFormCustomFields;
const updateFormsCustomFields = (forms, results) => {
  return forms.map(form => {
    const resObj = results.find(res => res.form.formId === form.formId && res.form.instanceId === form.instanceId);
    const response = resObj?.response;
    if (response) {
      return updateFormCustomFields(form, response);
    }
    return form;
  });
};
exports.updateFormsCustomFields = updateFormsCustomFields;