"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkForErrors = void 0;
var _commonsUtils = require("@sm360/commons-utils");
const checkForErrors = (values, fieldValidators) => {
  const errors = Object.keys(fieldValidators).reduce((accum, fieldName) => {
    let validatorFn = null;
    if (fieldValidators[fieldName] === 'validateIsNotEmpty') validatorFn = _commonsUtils.isEmpty;
    const isValid = validatorFn ? validatorFn(values[fieldName]) : true;
    if (!isValid) {
      accum[fieldName] = true;
    }
    return accum;
  }, {});
  return errors;
};
exports.checkForErrors = checkForErrors;