"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const getModelType = CBBType => {
  switch (CBBType) {
    case '$':
      return 'currency';
    default:
      return 'radio';
  }
};
const getShortCode = code => {
  switch (code) {
    case 'Y':
      return true;
    case 'N':
      return false;
    default:
      return code;
  }
};

/**
 * Recursive function generating an object containing each question
 * @param {*} questions      : list of questions
 * @param {*} parentQuestion : parent question of the list of questions
 */
const generateQuestion = function (questions) {
  let parentQuestion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
  return {
    ...questions.reduce((acc, q) => {
      // Get the parent's answer having showChildren == true
      const showChildrenAnswer = parentQuestion?.possibleAnswers.find(a => a.showChildren);
      const parentLinkCode = showChildrenAnswer ? {
        onlyFor: getShortCode(showChildrenAnswer.answerCode)
      } : {};
      return {
        ...acc,
        ...{
          [q.questionId]: {
            type: getModelType(q.type),
            labelKey: q.questionText,
            linkToParent: parentLinkCode,
            choices: q.possibleAnswers.map(a => {
              const code = a.answerCode;
              return {
                value: getShortCode(code),
                labelKey: a.answer
              };
            }),
            // Sub-questions
            questions: q?.childQuestions ? generateQuestion(q.childQuestions, q) : {}
          }
        }
      };
    }, {})
  };
};

/**
 *
 * @param {*} CBBQuestions questions that are specific to Dilawri provided by CBB via "tradeIn/questions" endpoint
 * @returns formatted model for displaying the question in the condition-report component
 */
const generateDilawriCBBFormModel = CBBQuestions => {
  const formattedModel = {
    iconName: 'rapport',
    sections: {
      section1: {
        questions: generateQuestion(CBBQuestions)
      }
    }
  };
  return formattedModel;
};
var _default = exports.default = generateDilawriCBBFormModel;