"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.WARRANTY_CANCELED = exports.VEHICLE_STOLEN = exports.VEHICLE_REBUILD = exports.VEHICLE_PURCHASE = exports.VEHICLE_CONDITION = exports.Trade360ToDeskingAnswersMapper = exports.TRUE_DISTANCE_TRAVELED = exports.TRUE_DISTANCE = exports.SUITABLE_TRANSPORTATION = exports.STRUCTURAL_DAMAGE = exports.RUST_PROOF = exports.REPLACED_TIRES = exports.REPLACED_BRAKES = exports.RECALL = exports.PARTS_TO_REPLACE = exports.OUTSIDE_REGISTRY = exports.ORIGINAL_OWNER = exports.OPEN_NOTICES = exports.NEED_REPARATION_OTHER_EXPLANATION = exports.MECHANICAL_PROTECTION = exports.MAJOR_EXTERIOR_INTERIOR_DAMAGE = exports.FOR_RESALE = exports.FIRE_DAMAGE = exports.DAMAGE_EXCEEDING_3000 = exports.COVERAGE_BALANCE = exports.CORPORATE_USE_EXPLANATION = exports.CORPORATE_USE = exports.COMPLIES_REQUIREMENT = exports.CLARIFY_REGISTRY = exports.CLARIFY_OPEN_NOTICES = exports.CLARIFY_DAMAGE = exports.CLARIFY_ACCIDENT = exports.BODY_PART_REPLACED = exports.AIR_BAGS_OPERATIONAL = void 0;
var _commonsUtils = require("@sm360/commons-utils");
const ORIGINAL_OWNER = exports.ORIGINAL_OWNER = 'originalOwner';
const VEHICLE_PURCHASE = exports.VEHICLE_PURCHASE = 'vehiclePurchase';
const VEHICLE_CONDITION = exports.VEHICLE_CONDITION = 'vehicleCondition';
const CORPORATE_USE = exports.CORPORATE_USE = 'corporateUse';
const CORPORATE_USE_EXPLANATION = exports.CORPORATE_USE_EXPLANATION = 'corporateUseExplanation';
const RUST_PROOF = exports.RUST_PROOF = 'rustProof';
const MECHANICAL_PROTECTION = exports.MECHANICAL_PROTECTION = 'mechanicalProtection';
const COVERAGE_BALANCE = exports.COVERAGE_BALANCE = 'coverageBalance';
const TRUE_DISTANCE = exports.TRUE_DISTANCE = 'trueDistance';
const TRUE_DISTANCE_TRAVELED = exports.TRUE_DISTANCE_TRAVELED = 'trueDistanceTraveled';
const DAMAGE_EXCEEDING_3000 = exports.DAMAGE_EXCEEDING_3000 = 'damageExceeding3000';
const CLARIFY_ACCIDENT = exports.CLARIFY_ACCIDENT = 'clarifyAccident';
const VEHICLE_REBUILD = exports.VEHICLE_REBUILD = 'vehicleRebuild';
const AIR_BAGS_OPERATIONAL = exports.AIR_BAGS_OPERATIONAL = 'airBagsOperational';
const FIRE_DAMAGE = exports.FIRE_DAMAGE = 'fireDamage';
const VEHICLE_STOLEN = exports.VEHICLE_STOLEN = 'vehicleStolen';
const STRUCTURAL_DAMAGE = exports.STRUCTURAL_DAMAGE = 'structuralDamage';
const BODY_PART_REPLACED = exports.BODY_PART_REPLACED = 'bodyPartReplaced';
const OPEN_NOTICES = exports.OPEN_NOTICES = 'openNotices';
const CLARIFY_OPEN_NOTICES = exports.CLARIFY_OPEN_NOTICES = 'clarifyOpenNotices';
const PARTS_TO_REPLACE = exports.PARTS_TO_REPLACE = 'partsToReplace';
const NEED_REPARATION_OTHER_EXPLANATION = exports.NEED_REPARATION_OTHER_EXPLANATION = 'needReparationOtherExplanation';
const REPLACED_TIRES = exports.REPLACED_TIRES = 'replacedTires';
const REPLACED_BRAKES = exports.REPLACED_BRAKES = 'replacedBrakes';
const MAJOR_EXTERIOR_INTERIOR_DAMAGE = exports.MAJOR_EXTERIOR_INTERIOR_DAMAGE = 'majorExteriorInteriorDamage';
const CLARIFY_DAMAGE = exports.CLARIFY_DAMAGE = 'clarifyDamage';
const OUTSIDE_REGISTRY = exports.OUTSIDE_REGISTRY = 'outsideRegistry';
const CLARIFY_REGISTRY = exports.CLARIFY_REGISTRY = 'clarifyRegistry';
const FOR_RESALE = exports.FOR_RESALE = 'forResale';
const WARRANTY_CANCELED = exports.WARRANTY_CANCELED = 'warrantyCanceled';
const COMPLIES_REQUIREMENT = exports.COMPLIES_REQUIREMENT = 'compliesRequirement';
const SUITABLE_TRANSPORTATION = exports.SUITABLE_TRANSPORTATION = 'suitableTransportation';
const RECALL = exports.RECALL = 'recall';
const yesNoChoices = [{
  value: true,
  labelKey: 'conditionReport.QUESTIONNAIRE.YES'
}, {
  value: false,
  labelKey: 'conditionReport.QUESTIONNAIRE.NO'
}];
const history = {
  questions: {
    [ORIGINAL_OWNER]: {
      type: 'radio',
      labelKey: 'conditionReport.QUESTIONNAIRE.HISTORY.ORIGINALOWNER',
      choices: yesNoChoices
    },
    [VEHICLE_PURCHASE]: {
      type: 'radio',
      labelKey: 'conditionReport.QUESTIONNAIRE.HISTORY.VEHICLEPURCHASE',
      choices: [{
        value: 'private',
        labelKey: 'conditionReport.QUESTIONNAIRE.HISTORY.VEHICLEPURCHASE_PRIVATE'
      }, {
        value: 'dealer',
        labelKey: 'conditionReport.QUESTIONNAIRE.HISTORY.VEHICLEPURCHASE_DEALER'
      }]
    },
    [VEHICLE_CONDITION]: {
      type: 'radio',
      labelKey: 'conditionReport.QUESTIONNAIRE.HISTORY.VEHICLECONDITION',
      choices: [{
        value: 'perfect',
        labelKey: 'conditionReport.QUESTIONNAIRE.HISTORY.VEHICLECONDITION_PERFECT'
      }, {
        value: 'ok',
        labelKey: 'conditionReport.QUESTIONNAIRE.HISTORY.VEHICLECONDITION_OK'
      }, {
        value: 'rough',
        labelKey: 'conditionReport.QUESTIONNAIRE.HISTORY.VEHICLECONDITION_ROUGH'
      }]
    },
    [CORPORATE_USE]: {
      type: 'radio',
      labelKey: 'conditionReport.QUESTIONNAIRE.HISTORY.CORPORATEUSE',
      choices: yesNoChoices,
      questions: {
        [CORPORATE_USE_EXPLANATION]: {
          showQuestionText: false,
          linkToParent: {
            onlyFor: true
          },
          displayNestedInsideParent: true,
          type: 'textarea',
          labelKey: 'conditionReport.QUESTIONNAIRE.HISTORY.CORPORATEUSEEXPLANATION'
        }
      }
    },
    [RUST_PROOF]: {
      type: 'radio',
      labelKey: 'conditionReport.QUESTIONNAIRE.HISTORY.RUSTPROOF',
      choices: yesNoChoices
    },
    [MECHANICAL_PROTECTION]: {
      type: 'radio',
      labelKey: 'conditionReport.QUESTIONNAIRE.HISTORY.MECHANICALPROTECTION',
      choices: yesNoChoices,
      questions: {
        [COVERAGE_BALANCE]: {
          linkToParent: {
            onlyFor: true
          },
          labelKey: 'conditionReport.QUESTIONNAIRE.HISTORY.COVERAGEBALANCE',
          type: 'textarea'
        }
      }
    },
    [TRUE_DISTANCE]: {
      type: 'radio',
      labelKey: 'conditionReport.QUESTIONNAIRE.HISTORY.TRUEDISTANCE',
      choices: yesNoChoices,
      questions: {
        [TRUE_DISTANCE_TRAVELED]: {
          linkToParent: {
            onlyFor: false
          },
          labelKey: 'conditionReport.QUESTIONNAIRE.HISTORY.DISTANCEBALANCE',
          type: 'textarea'
        }
      }
    }
  }
};
const susDamage = {
  questions: {
    [DAMAGE_EXCEEDING_3000]: {
      type: 'radio',
      labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.DAMAGEEXCEEDING3000',
      choices: yesNoChoices,
      questions: {
        [CLARIFY_ACCIDENT]: {
          linkToParent: {
            onlyFor: true
          },
          displayNestedInsideParent: true,
          type: 'textarea',
          labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.CLARIFYDAMAGE'
        }
      }
    },
    [VEHICLE_REBUILD]: {
      labelKey: 'conditionReport.QUESTIONNAIRE.VEHICLEREBUILD',
      type: 'radio',
      choices: yesNoChoices
    },
    [AIR_BAGS_OPERATIONAL]: {
      type: 'radio',
      labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.AIRBAGSOPERATIONAL',
      choices: yesNoChoices
    },
    [FIRE_DAMAGE]: {
      labelKey: 'conditionReport.QUESTIONNAIRE.FIREDAMAGE',
      type: 'radio',
      choices: yesNoChoices
    },
    [VEHICLE_STOLEN]: {
      labelKey: 'conditionReport.QUESTIONNAIRE.VEHICLESTOLEN',
      type: 'radio',
      choices: yesNoChoices
    },
    [STRUCTURAL_DAMAGE]: {
      labelKey: 'conditionReport.QUESTIONNAIRE.STRUCTURALDAMAGE',
      type: 'radio',
      choices: yesNoChoices
    },
    [BODY_PART_REPLACED]: {
      labelKey: 'conditionReport.QUESTIONNAIRE.PANELREPLACED',
      type: 'radio',
      choices: yesNoChoices
    }
  }
};
const repairsRequired = {
  questions: {
    [OPEN_NOTICES]: {
      labelKey: 'conditionReport.QUESTIONNAIRE.OPENNOTICE',
      type: 'radio',
      choices: yesNoChoices,
      questions: {
        [CLARIFY_OPEN_NOTICES]: {
          linkToParent: {
            onlyFor: true
          },
          labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.CLARIFYDAMAGE',
          type: 'textarea'
        }
      }
    },
    [PARTS_TO_REPLACE]: {
      type: 'checkbox',
      labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.PARTSTOREPLACE',
      choices: [{
        value: 'none',
        disableOthers: true,
        labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_NONE'
      }, {
        value: 'engineTransmissionPowertrain',
        labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_ENGINETRANSPOWER'
      }, {
        value: 'suspension',
        labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_SUSPENSION'
      }, {
        value: 'computer',
        labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_COMPUTER'
      }, {
        value: 'electrical',
        labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_ELECTRICAL'
      }, {
        value: 'fuelOperationSystem',
        labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_FUELOPERATIONSYSTEM'
      }, {
        value: 'airConditioningSystem',
        labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_AIRCONDITIONINGSYSTEM'
      }, {
        value: 'abs',
        labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_ABS'
      }, {
        value: 'other',
        labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.PARTSTOREPLACE_OTHER'
      }],
      questions: {
        [NEED_REPARATION_OTHER_EXPLANATION]: {
          linkToParent: {
            anyExcept: 'none'
          },
          displayNestedInsideParent: true,
          type: 'textarea',
          labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.NEEDREPARATIONOTHEREXPLANATION'
        }
      }
    },
    [REPLACED_TIRES]: {
      type: 'radio',
      labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.REPLACEDTIRES',
      choices: yesNoChoices
    },
    [REPLACED_BRAKES]: {
      type: 'radio',
      labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.REPLACEDBRAKES',
      choices: yesNoChoices
    },
    [MAJOR_EXTERIOR_INTERIOR_DAMAGE]: {
      type: 'radio',
      labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.MAJOREXTERIORINTERIORDAMAGE',
      choices: yesNoChoices,
      questions: {
        [CLARIFY_DAMAGE]: {
          linkToParent: {
            onlyFor: true
          },
          displayNestedInsideParent: true,
          type: 'textarea',
          labelKey: 'conditionReport.QUESTIONNAIRE.CONDITION.CLARIFYDAMAGE'
        }
      }
    }
  }
};
const registration = {
  questions: {
    [OUTSIDE_REGISTRY]: {
      labelKey: 'conditionReport.QUESTIONNAIRE.OUTSIDEREGISTRY',
      type: 'radio',
      choices: yesNoChoices,
      questions: {
        [CLARIFY_REGISTRY]: {
          linkToParent: {
            onlyFor: true
          },
          displayNestedInsideParent: true,
          type: 'textarea',
          labelKey: 'conditionReport.QUESTIONNAIRE.CLARIFYREGISTRY'
        }
      }
    },
    [FOR_RESALE]: {
      labelKey: 'conditionReport.QUESTIONNAIRE.FORRESALE',
      type: 'radio',
      choices: yesNoChoices
    },
    [WARRANTY_CANCELED]: {
      labelKey: 'conditionReport.QUESTIONNAIRE.WARRANTLYCANCELED',
      type: 'radio',
      choices: yesNoChoices
    }
  }
};
const addDisclosures = {
  questions: {
    [COMPLIES_REQUIREMENT]: {
      labelKey: 'conditionReport.QUESTIONNAIRE.COMPLIESREQUIREMENT',
      type: 'radio',
      choices: yesNoChoices
    },
    [SUITABLE_TRANSPORTATION]: {
      labelKey: 'conditionReport.QUESTIONNAIRE.SUITABLETRANSPORTATION',
      type: 'radio',
      choices: yesNoChoices
    },
    [RECALL]: {
      labelKey: 'conditionReport.QUESTIONNAIRE.RECALL',
      type: 'radio',
      choices: yesNoChoices
    }
  }
};
const Trade360ToDeskingAnswersMapper = answers => {
  const {
    corporateUse,
    hasUsed,
    hadAnAccident,
    partsToReplace,
    panelReplaced,
    absOperational,
    majorExteriorInteriorDamage,
    anyScratches
  } = answers;
  const adjsutedAnswers = {
    ...answers
  };

  // corporateUse (Vehicle used as taxi, police, etc..)
  // since corporateUse is present in Trade360 and prior to this hasUsed never made it into Desking production
  // (or did but for only a week)
  // we will use enforce corporateUse first
  if ((0, _commonsUtils.isEmpty)(corporateUse) && !(0, _commonsUtils.isEmpty)(hasUsed)) {
    adjsutedAnswers[CORPORATE_USE] = true;
  }
  if (typeof panelReplaced !== 'undefined' && panelReplaced !== '') {
    adjsutedAnswers[BODY_PART_REPLACED] = panelReplaced;
    adjsutedAnswers.panelReplaced = '';
  }

  // damageExceeding3000
  // damageExceeding3000 never made it into Desking production prior the time of this update
  // this part is a little tricky, we are always enforcing damageExceeding3000 to be the new source
  // to not allow for this to get mixed up we immediately erase hadAnAccident coming from Trade360
  // so if Desking decides to change the value for damageExceeding3000 we won't have it overriden by hadAnAccident
  if (!(0, _commonsUtils.isEmpty)(hadAnAccident)) {
    adjsutedAnswers[DAMAGE_EXCEEDING_3000] = !!(hadAnAccident === 'lessThan3000' || hadAnAccident === '3000AndMore');
    adjsutedAnswers.hadAnAccident = ''; // this will erase the Trade360 side answer
  }
  if (!(0, _commonsUtils.isEmpty)(partsToReplace) && partsToReplace.find(part => part === 'engine' || part === 'transmission' || part === 'powertrain')) {
    adjsutedAnswers[PARTS_TO_REPLACE].push('engineTransmissionPowertrain');
    adjsutedAnswers[PARTS_TO_REPLACE] = adjsutedAnswers[PARTS_TO_REPLACE].filter(part => part !== 'engine' && part !== 'transmission' && part !== 'powertrain');
  }

  // absOperational
  // if absOperational is false we will mark it in partsToReplace
  if (absOperational === false) {
    // we will add it to partsToReplace
    adjsutedAnswers[PARTS_TO_REPLACE].push('abs');
    adjsutedAnswers.absOperational = null;
  }
  if (anyScratches === true && (majorExteriorInteriorDamage === '' || majorExteriorInteriorDamage === false)) {
    adjsutedAnswers[MAJOR_EXTERIOR_INTERIOR_DAMAGE] = true;
  }
  return adjsutedAnswers;
};
exports.Trade360ToDeskingAnswersMapper = Trade360ToDeskingAnswersMapper;
const model = {
  iconName: 'rapport',
  sections: {
    history,
    susDamage,
    repairsRequired,
    registration,
    addDisclosures
  }
};
var _default = exports.default = model;