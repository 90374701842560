"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMemoizedQueryData = exports.reorderById = exports.getDefaultComboboxProps = exports.getData = void 0;
var _react = require("react");
const getData = query => query?.data || [];
exports.getData = getData;
const getDefaultComboboxProps = t => ({
  color: 'secondary',
  displaySearchBar: true,
  splitDisplay: true,
  placeholder: t('commons.selectMultiple.label.noSelection'),
  customMessage: {
    required: t('commons.validation.required')
  },
  labels: {
    inputSelectionLabel: t('commons.selectMultiple.label.selection'),
    inputAllSelectedLabel: t('commons.selectMultiple.label.allSelected'),
    searchLabel: t('commons.selectMultiple.label.search'),
    clearButtonLabel: t('commons.selectMultiple.label.clearbutton'),
    selectButtonLabel: t('commons.selectMultiple.label.selectbutton'),
    selectedLabel: t('commons.selectMultiple.label.selected'),
    availableLabel: t('commons.selectMultiple.label.available')
  }
});
exports.getDefaultComboboxProps = getDefaultComboboxProps;
const useMemoizedQueryData = query => {
  return (0, _react.useMemo)(() => query?.data || [], [query?.data]);
};
exports.useMemoizedQueryData = useMemoizedQueryData;
const reorderById = function (list) {
  let idOrder = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  // Extract the items matching the idOrder from the list
  const orderedItems = idOrder.map(id => list.find(item => item?.id === id)).filter(Boolean);

  // Remove the items that are already in the orderedItems list from the original list
  const remainingItems = list.filter(item => !idOrder.includes(item?.id));

  // Combine the ordered items followed by the remaining items
  return [...orderedItems, ...remainingItems];
};
exports.reorderById = reorderById;