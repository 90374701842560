"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (state, _ref) => {
  let {
    isOpen
  } = _ref;
  return {
    ...state,
    contactMerger: {
      isOpen,
      isFromDelivery: isOpen ? state.contactMerger.isFromDelivery : false,
      isFromDMS: isOpen ? state.contactMerger.isFromDMS : false
    }
  };
};
exports.default = _default;